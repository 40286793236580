<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat color="green darken-2" dark>
                    <v-toolbar-title>Set up your organization</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-progress-linear color="green darken-2" height="8" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <template v-if="step === 'check_status'">
                    <v-card-text>
                        <p>Preparing...</p>
                        <v-progress-circular color="green darken-2" size="24" indeterminate class="mb-6"></v-progress-circular>
                    </v-card-text>
                </template>
                <template v-if="step === 'setup_brandprofile' && isViewReady">
                    <v-card-text>
                        <p>Unicorn Springs partners with BrandProfile for centralized brand asset management.</p>
                        <p>BrandProfile allows you to customize your organization's storefront with your logo images, color palette, and more.</p>
                        <!-- <a @click.prevent="connectBrandProfile"><span style="border-bottom: 1px solid">Set up a BrandProfile</span></a> -->
                        <p>You will set this up later. For now let's continue to the next step.</p>
                        <a @click.prevent="connectBrandProfile"><span style="border-bottom: 1px solid">Continue</span></a>
                    </v-card-text>
                </template>
                <template v-if="step === 'setup_stripe' && isViewReady">
                    <v-card-text>
                        <p>Unicorn Springs partners with Stripe for secure payments.</p>
                        <p>When customers pay at your online store hosted by Unicorn Springs, the payment information is collected and processed by Stripe, which then subtracts transaction fees and the Unicorn Springs fee and deposits the remaining amount into your bank account.</p>
                        <a @click.prevent="connectStripeAccount"><span style="border-bottom: 1px solid">Set up a Stripe account</span></a>
                    </v-card-text>
                </template>
                <template v-if="step === 'notice' && isViewReady">
                    <v-card-text>
                        <p>Unicorn Springs is a new platform. We are currently in our "alpha" testing phase. Some features may be missing or inoperable. We are we are working relentlessly to complete all our planned features.</p>
                        <p>Your organization is currently on the <strong>Essential</strong> plan which means we don't make any money unless you do.</p>
                        <p>If you find that some feature is missing or broken, please use the question mark icon at the top of the page to get in touch with us. We actively prioritize the features and issues that customers like you are telling us are the most important.</p>
                        <p>The next step is to continue to your organization dashboard from where you can create and manage your available products.</p>
                        <!-- <a @click.prevent="connectBrandProfile"><span style="border-bottom: 1px solid">Continue</span></a> -->
                        <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">Continue to dashboard</router-link>
                        <!-- <v-btn color="green darken-2 white--text" @click="setup" class="mt-4">Continue</v-btn> -->
                    </v-card-text>
                </template>
            </v-card>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState } from 'vuex';
// import BrandList from '@/components/BrandList.vue';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        steps: ['check_status', 'setup_brandprofile', 'setup_stripe', 'notice'],
        step: 'check_status',
        product: null,
        brandprofile: null,
        stripeAccountId: null,
        submitTimestamp: null,
        redirect: null,
        newOrganizationName: null,
        // older ones, check if we're using
        interactionId: null,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
            // organizationList: (state) => state.organizationList,
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
    },
    watch: {
        focus() {
            if (this.step === 'create_organization') {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newOrganizationNameInput'); }, 1);
                });
            }
        },
        /*
        $route(newValue) {
            if (newValue.query.step !== this.step) {
                this.step = newValue.query.step;
                if (newValue.query.step === 'start') {
                    this.$nextTick(() => {
                        setTimeout(() => { this.$activateInput('usernameInput'); }, 1);
                    });
                }
            }
        },
        */
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async check() {
            try {
                this.resetErrors();
                this.$store.commit('loading', { checkUserSetup: true });

                // TODO: these should be loaded concurrently
                await this.loadBrandProfile();
                await this.loadStripeAccountId();

                if (!this.brandprofile && !this.$route.query.skip_brandprofile && ['check_status', 'setup_brandprofile'].includes(this.$route.query.step ?? 'check_status')) {
                    this.step = 'setup_brandprofile';
                    this.isViewReady = true;
                    const query = { ...this.$route.query, step: 'setup_brandprofile', t: Date.now() };
                    this.$router.replace({ name: 'organization-setup', query });
                    return;
                }

                if (!this.stripeAccountId && !this.$route.query.skip_stripe && ['check_status', 'setup_stripe'].includes(this.$route.query.step ?? 'check_status')) {
                    this.step = 'setup_stripe';
                    this.isViewReady = true;
                    const query = { ...this.$route.query, step: 'setup_stripe', t: Date.now() };
                    this.$router.replace({ name: 'organization-setup', query });
                    return;
                }

                this.step = 'notice';
                this.isViewReady = true;
                const query = { ...this.$route.query, step: 'notice', t: Date.now() };
                this.$router.replace({ name: 'organization-setup', query });
            } catch (err) {
                console.error('failed to check user setup', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkUserSetup: false });
                this.isViewReady = true;
            }
        },
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                return response?.content ?? null;
            } catch (err) {
                console.error('failed to load setting', err);
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async loadBrandProfile() {
            this.brandprofile = await this.loadSetting('brandprofile');
        },
        async loadStripeAccountId() {
            this.stripeAccountId = await this.loadSetting('stripe_account_id');
        },
        async connectStripeAccount() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { connectStripeAccount: true });
                const response = await this.$client.organization(this.$route.params.organizationId).stripe.connect({ intent: 'setup' });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://connect.stripe.com/setup/s/CAFwVkHysDCz","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('failed to get link to stripe', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Stripe' });
            } finally {
                this.$store.commit('loading', { connectStripeAccount: false });
            }
        },
        async connectBrandProfile() {
            this.step = 'setup_stripe';
            this.isViewReady = true;
            const query = {
                ...this.$route.query,
                step: 'setup_stripe',
                skip_brandprofile: '1',
                t: Date.now(),
            };
            this.$router.replace({ name: 'organization-setup', query });
            this.check();
            // TODO: do the api call and redirect to brandprofile, as shown below.  temporarily disabled until brandprofile implements the connect api.
            /*
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { connectBrandProfile: true });
                const response = await this.$client.organization(this.$route.params.organizationId).brandprofile.connect();
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://brandprofile.org/connect?token=asdfasdf","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('failed to get link to stripe', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with BrandProfile' });
            } finally {
                this.$store.commit('loading', { connectBrandProfile: false });
            }
            */
        },
    },
    mounted() {
        this.check();
        /*
        // if user arrives with `email` and `step=verify_email`, call signup to check
        // the email address, if it's not verified we will redirect to EtherLink, and
        // eventually return here with a verified address or cancel the interaction
        if (this.$route.query.step === 'verify_email' && this.$route.query.email) {
            this.step = 'verify_email';
            this.username = this.$route.query.email;
            this.signup();
            return;
        }

        // if user arrives with `email` and `step=display_name`, show the email address and
        // ask user for their display name
        if (this.$route.query.step === 'display_name' && this.$route.query.email) {
            this.step = 'display_name';
            this.username = this.$route.query.email;
            this.isViewReady = true;
            this.$nextTick(() => {
                setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
            });
            return;
        }

        // if user arrives with `email` and `step=setup_loginfront`, check that user is authenticated
        // and has the same email address and show that step, otherwise start over
        if (this.$route.query.step === 'setup_loginfront' && this.$route.query.email) {
            if (this.session.isAuthenticated && this.user.email === this.$route.query.email) {
                this.step = 'setup_loginfront';
                this.username = this.$route.query.email;
                this.isViewReady = true;
                return;
            }
        }

        // the first time user arrives on this page, there would not be an interaction id in the query,
        // but there could be an email address to pre-fill;
        // the second time the user arrives on this page (after email verification is completed), there
        // would be an interaction id also;
        // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
        this.interactionId = this.$route.query.i;
        this.username = this.$route.query.email ?? '';
        if (this.interactionId && this.username) {
            this.signup();
            return;
        }

        this.isViewReady = true;
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('usernameInput'); }, 1);
        });
        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
        */
    },
};
</script>
